<template>
	<div class="row">
		<div class="col-12">
			<div class="card pt-1 pb-1" style="margin-top:-35px; margin-bottom: 5px;">
				<div class="wizard-form" id="grad1">
					<div class="row justify-content-center mt-0">
						<div class="col-12">
							<div class="card mt-1 mb-1 bxShadow">
								<h5 class="text-center"><strong>{{ titleform }}</strong></h5>
								<div class="row">
									<div class="col-md-12 mx-0 mb-1">
										<form id="msform" class="mt-2">

											<!-- progressbar -->
											<ul id="progressbar" class="text-center">
												<a @click="changeStep(1)">
													<li :class="step >= 1 ? 'active' : ''" id="geral"><strong>Gerais e
															Veículo</strong></li>
												</a>
												<a @click="changeStep(2)">
													<li :class="step >= 2 ? 'active' : ''" id="financeiro"><strong>Dados
															Financeiros</strong></li>
												</a>
												<a v-show="false" @click="changeStep(4)">
													<li :class="step >= 4 ? 'active' : ''" id="agente">
														<strong>Agente</strong>
													</li>
												</a>
												<a @click="changeStep(3)">
													<li :class="step >= 3 ? 'active' : ''" id="comprador">
														<strong>Comprador</strong>
													</li>
												</a>
											</ul>
											<!-- end progressbar -->

											<!-- gerais e veículos -->
											<fieldset v-if="step == 1">
												<div class="form-card">
													<div class="row form-distance" style="margin-top:-15px">

														<!-- tipo_operacao -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-tipo_operacao">Tipo de Operação</label>
																<select id="input-tipo_operacao"
																	v-bind:class="{ 'is-invalid': errors.tipo_operacao }"
																	v-model="tipo_operacao" class="form-control"
																	placeholder="Tipo de Operação">
																	<option v-show="tipo_operacao === 1" value="1">1 -
																		Inclusão do registro de contrato
																	</option>
																	<option value="2">2 - Alteração do registro de
																		contrato
																	</option>
																	<option value="3">3 - Inclusão do aditivo de
																		contrato
																	</option>
																	<option value="4">4 - Alteração do aditivo de
																		contrato
																	</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.tipo_operacao">
																	<p>{{ errors.tipo_operacao }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end tipo_operacao -->

														<!-- num_contrato_origem -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_contrato_origem">Nº Contrato
																	Origem</label>
																<input type="text" id="input-num_contrato_origem"
																	v-bind:class="{
																		'is-invalid': errors.num_contrato_origem
																	}" class="form-control" placeholder="Nº Contrato Origem" v-model="num_contrato_origem" maxlength="14"
																	@input="validarCampos('type_numContrato', 'num_contrato_origem')" />
																<div class="invalid-feedback"
																	v-if="errors.num_contrato_origem">
																	<p>{{ errors.num_contrato_origem }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-4 -->
														<!-- end num_contrato_origem -->

														<!-- num_aditivo_origem -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_aditivo_origem">Nº Aditivo
																	Origem</label>
																<input type="text" id="input-num_aditivo_origem"
																	v-bind:class="{
																		'is-invalid': errors.num_aditivo_origem
																	}" class="form-control" placeholder="Nº Aditivo Origem" v-model="num_aditivo_origem" maxlength="14"
																	:disabled="tipo_operacao === '1' || tipo_operacao === '2' ||
																		tipo_operacao === 1 || tipo_operacao === 2" @input="validarCampos('type_numContrato', 'num_aditivo_origem')" />
																<div class="invalid-feedback"
																	v-if="errors.num_aditivo_origem">
																	<p>{{ errors.num_aditivo_origem }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end num_aditivo_origem -->

														<!-- chassi -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-chassi">Chassi</label>
																<input type="text" id="input-chassi"
																	v-bind:class="{ 'is-invalid': errors.chassi }"
																	class="form-control" placeholder="Chassi"
																	v-model="chassi" maxlength="21"
																	@input="validarCampos('type_number_Lyrics', 'chassi')" />
																<div class="invalid-feedback" v-if="errors.chassi">
																	<p>{{ errors.chassi }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-4 -->
														<!-- end chassi -->

														<!--condicao do veiculo-->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label">Condição do
																	Veículo</label>
																<select id="input-indicador_novo_usado"
																	class="form-control" v-model="indicador_novo_usado">
																	<option value="N">Novo</option>
																	<option value="U">Seminovo</option>
																</select>
															</div>
														</div>
														<!--end condicao do veiculo-->

														<!-- remarcacao -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-remarcacao">Remarcação</label>
																<select type="text" id="input-remarcacao"
																	v-bind:class="{ 'is-invalid': errors.remarcacao }"
																	class="form-control" placeholder="Remarcação"
																	v-model="remarcacao" required="true"
																	:disabled="indicador_novo_usado === 'N'">
																	<option value="1">Remarcado</option>
																	<option value="2">Não Remarcado</option>
																</select>
																<div class="invalid-feedback" v-if="errors.remarcacao">
																	<p>{{ errors.remarcacao }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end remarcacao -->

														<!-- uf_licenciamento -->
														<div class="col-md-2" style="display:none;">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_licenciamento">UF
																	Licenciamento</label>
																<select id="input-uf_licenciamento" v-bind:class="{
																	'is-invalid': errors.uf_licenciamento
																}" class="form-control" placeholder="UF Placa" v-model="uf_licenciamento" :disabled=true>
																	<option v-for="item in states">{{
																		item
																	}}</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.uf_licenciamento">
																	<p>{{ errors.uf_licenciamento }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-6 -->
														<!-- end uf_placa -->

														<!-- uf_placa -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_placa">UF
																	Placa</label>
																<select id="input-uf_placa" v-bind:class="{
																	'is-invalid': errors.uf_placa
																}" class="form-control" placeholder="UF Placa" v-model="uf_placa"
																	:disabled="indicador_novo_usado === 'N'">
																	<option v-for="item in states">{{
																		item
																	}}</option>
																</select>
																<div class="invalid-feedback" v-if="errors.uf_placa">
																	<p>{{ errors.uf_placa }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-6 -->
														<!-- end uf_placa -->

														<!-- placa -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-placa">Placa</label>
																<input type="text" id="input-placa"
																	v-bind:class="{ 'is-invalid': errors.placa }"
																	class="form-control" placeholder="Placa"
																	v-model="placa" maxlength="7"
																	:disabled="indicador_novo_usado === 'N'"
																	@input="validarCampos('type_number_Lyrics', 'placa')" />
																<div class="invalid-feedback" v-if="errors.placa">
																	<p>{{ errors.placa }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-4 -->
														<!-- end placa -->

														<!-- renavam -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-renavam">Renavam</label>
																<input type="tel" id="input-renavam"
																	v-bind:class="{ 'is-invalid': errors.renavam }"
																	class="form-control" placeholder="Renavam"
																	v-model="renavam" required="true" maxlength="11"
																	:disabled="indicador_novo_usado === 'N'"
																	@input="validarCampos('type_numbers', 'renavam')">
																<div class="invalid-feedback" v-if="errors.renavam">
																	<p>{{ errors.renavam }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end renavam -->

														<!-- ano_fabricacao -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ano_fabricacao">Ano Fabricação</label>
																<input type="tel" id="input-ano_fabricacao"
																	v-bind:class="{
																		'is-invalid': errors.ano_fabricacao
																	}" class="form-control" placeholder="Ano Fabricação" v-model="ano_fabricacao" maxlength="4"
																	@input="validarCampos('type_numbers', 'ano_fabricacao')" />
																<div class="invalid-feedback"
																	v-if="errors.ano_fabricacao">
																	<p>{{ errors.ano_fabricacao }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-2 -->
														<!-- end ano_fabricacao -->

														<!-- ano_modelo -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ano_modelo">Ano
																	Modelo</label>
																<input type="text" id="input-ano_modelo" v-bind:class="{
																	'is-invalid': errors.ano_modelo
																}" class="form-control" placeholder="Ano Modelo" v-model="ano_modelo" maxlength="4"
																	@input="validarCampos('type_numbers', 'ano_modelo')" />
																<div class="invalid-feedback" v-if="errors.ano_modelo">
																	<p>{{ errors.ano_modelo }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-2 -->
														<!-- end ano_modelo -->

														<!-- Tipo de veiculos -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-tipo_veiculo">Tipo de Veículo</label>
																<v-select label="nome" @input="getMarcas"
																	@change="getMarcas" :reduce="nome => nome.id"
																	:options="tipos" v-model="tipo_id"></v-select>
																<div class="invalid-feedback" v-if="errors.tipo_id">
																	<p>{{ errors.tipo_id }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- Tipo de veiculos -->

														<!-- marcas -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-marcas">Marcas
																	do veículo</label>
																<v-select label="nome" @input="getModelos"
																	@change="getModelos" :options="marcas"
																	:reduce="nome => nome.id"
																	v-model="marca_id"></v-select>
																<div class="invalid-feedback" v-if="errors.marca">
																	<p>{{ errors.marca }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-2 -->
														<!-- end marcas -->

														<!-- Modelos -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-modelos">Modelos</label>
																<v-select label="nome" :options="modelos"
																	:reduce="nome => nome.id"
																	v-model="modelo_id"></v-select>
																<div class="invalid-feedback" v-if="errors.modelos">
																	<p>{{ errors.modelos }}</p>
																</div>
															</div>
															<!-- form group modelos-->
														</div>
														<!-- col-md-2 modelos-->

														<!-- cores -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-modelos">Cores</label>
																<v-select label="nome" :options="cores" v-model="cor_id"
																	:reduce="nome => nome.id"></v-select>
																<div class="invalid-feedback" v-if="errors.cores">
																	<p>{{ errors.cores }}</p>
																</div>
															</div>
															<!-- form group cores-->
														</div>
														<!-- col-md-2 cores-->

														<div class="col-md-12">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-modelos">Observações</label>
																<textarea style="height: 90px" id="input-observacao"
																	class="form-control" placeholder="Observações"
																	v-bind:class="{
																		'is-invalid': errors.observacao
																	}" v-model="observacao" maxlength="255">
		                                   						 </textarea>
															</div>
														</div>
													</div>
												</div>
												<button @click="changeStep(2)" class="btn-next">Próximo</button>
											</fieldset>
											<!-- end gerais e veículos -->

											<!--- dados financeiros -->
											<fieldset v-if="step == 2">
												<div class="form-card">
													<div class="row form-distance" style="margin-top:-15px">
														<!-- num_contrato -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_contrato">Nº Contrato</label>
																<input type="text" id="input-num_contrato" v-bind:class="{
																	'is-invalid': errors.num_contrato
																}" class="form-control" placeholder="Nº Contrato" v-model="num_contrato" maxlength="20"
																	@input="validarCampos('type_numContrato', 'num_contrato')" />
																<div class="invalid-feedback"
																	v-if="errors.num_contrato">
																	<p>{{ errors.num_contrato }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end num_contrato -->

														<!-- data_contrato -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_contrato">Data Contrato</label>
																<input type="date" id="input-data_contrato"
																	v-bind:class="{
																		'is-invalid': errors.data_contrato
																	}" class="form-control" placeholder="Data Contrato" v-model="data_contrato" />
																<div class="invalid-feedback"
																	v-if="errors.data_contrato">
																	<p>{{ errors.data_contrato }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end data_contrato -->

														<!-- qtd_parcelas -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-qtd_parcelas">Qtd Parcelas</label>
																<input type="tel" id="input-qtd_parcelas" v-bind:class="{
																	'is-invalid': errors.qtd_parcelas
																}" class="form-control" placeholder="Qtd Parcelas" v-model="qtd_parcelas" maxlength="3"
																	@input="validarCampos('type_numbers', 'qtd_parcelas')" />
																<div class="invalid-feedback"
																	v-if="errors.qtd_parcelas">
																	<p>{{ errors.qtd_parcelas }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-2 -->
														<!-- end qtd_parcelas -->

														<!-- num_gravame -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_gravame">Nº
																	Gravame</label>
																<input type="tel" id="input-num_gravame" v-bind:class="{
																	'is-invalid': errors.num_gravame
																}" class="form-control" placeholder="Nº Gravame" v-model="num_gravame" maxlength="8"
																	@input="validarCampos('type_gravame', 'num_gravame')" />
																<div class="invalid-feedback" v-if="errors.num_gravame">
																	<p>{{ errors.num_gravame }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end num_gravame -->

														<!-- tipo_gravame -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-tipo_gravame">Tipo Gravame</label>
																<select type="text" id="input-tipo_gravame"
																	v-bind:class="{
																		'is-invalid': errors.tipo_gravame
																	}" class="form-control" placeholder="Tipo Gravame" v-model="tipo_gravame">
																	<option value="1">Arrendamento Mercantil</option>
																	<option value="2">Reserva de Domínio</option>
																	<option value="3">Alienação Fidunciária</option>
																	<option value="9">Penhor</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.tipo_gravame">
																	<p>{{ errors.tipo_gravame }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end tipo_gravame -->

														<!-- taxa_juro_mes -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_juro_mes">Taxa Juros Mês</label>
																<money v-model="taxa_juro_mes" class="form-control"
																	v-bind="percentmoney" maxlength="9">
																</money>
																<div class="invalid-feedback"
																	v-if="errors.taxa_juro_mes">
																	<p>{{ errors.taxa_juro_mes }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end taxa_juro_mes -->

														<!-- taxa_juro_ano -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_juro_ano">Taxa Juros Ano</label>
																<money v-model="taxa_juro_ano" class="form-control"
																	v-bind="percentmoney" maxlength="9">
																</money>
																<div class="invalid-feedback"
																	v-if="errors.taxa_juro_ano">
																	<p>{{ errors.taxa_juro_ano }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end taxa_juro_ano -->

														<!-- taxa_juro_multa -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_juro_multa">Tx. Juros
																	Multa?</label>
																<select type="text" id="input-taxa_juro_multa" @change="disableMulta(
																	taxa_juro_multa,
																	'taxa_juro_multa')
																	" v-bind:class="{
																		'is-invalid': errors.taxa_juro_multa
																	}" class="form-control" placeholder="Taxa de Juros Multa" v-model="taxa_juro_multa">
																	<option value="NAO">Não</option>
																	<option value="SIM">Sim</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.taxa_juro_multa">
																	<p>{{ errors.taxa_juro_multa }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end taxa_juro_multa -->

														<!-- taxa_multa -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_multa">
																	Taxa Juros Multa</label>
																<money v-model="taxa_multa"
																	class="form-control taxa_juro_multa"
																	v-bind="percentmoney" maxlength="9"
																	v-bind:class="{ 'is-invalid': errors.taxa_multa }">
																</money>
																<div class="invalid-feedback" v-if="errors.taxa_multa">
																	<p>{{ errors.taxa_multa }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end taxa_multa -->

														<!-- taxa_mora_dia -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_mora_dia">Tx. Mora Dia?</label>
																<select type="text" id="input-taxa_mora_dia" @change="disableMora(taxa_mora_dia, 'taxa_mora_dia')
																	" v-bind:class="{
																		'is-invalid': errors.taxa_mora_dia
																	}" class="form-control" placeholder="" v-model="taxa_mora_dia">
																	<option value="NAO">Não</option>
																	<option value="SIM">Sim</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.taxa_mora_dia">
																	<p>{{ errors.taxa_mora_dia }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end taxa_mora_dia -->

														<!-- taxa_mora -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label" for="input-taxa_mora">
																	Taxa Mora Dia</label>
																<money v-model="taxa_mora"
																	class="form-control taxa_mora_dia"
																	v-bind="percentmoney" maxlength="9"
																	v-bind:class="{ 'is-invalid': errors.taxa_mora }">
																</money>
																<div class="invalid-feedback" v-if="errors.taxa_mora">
																	<p>{{ errors.taxa_mora }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end taxa_mora -->

														<!-- indicativo_penalidade -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-indicativo_penalidade">Penalidade?</label>
																<select type="text" id="input-indicativo_penalidade"
																	@change="disablePenalidade(
																		indicativo_penalidade,
																		'indicativo_penalidade')
																		" v-bind:class="{
																			'is-invalid': errors.indicativo_penalidade
																		}" class="form-control" placeholder="" v-model="indicativo_penalidade">
																	<option value="NAO">Não</option>
																	<option value="SIM">Sim</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.indicativo_penalidade">
																	<p>{{ errors.indicativo_penalidade }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end indicativo_penalidade -->

														<!-- penalidade -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-penalidade">Penalidade</label>
																<input type="text" id="input-penalidade" v-bind:class="{
																	'is-invalid': errors.penalidade
																}" class="form-control penalidade" placeholder="Penalidade" v-model="penalidade" maxlength="50" />
																<div class="invalid-feedback" v-if="errors.penalidade">
																	<p>{{ errors.penalidade }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-6 -->
														<!-- end penalidade -->

														<!-- indicativo_comissao -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-indicativo_comissao">Comissão?</label>
																<select type="text" id="input-indicativo_comissao"
																	@change="disableComissao(
																		indicativo_comissao,
																		'indicativo_comissao')
																		" v-bind:class="{
																			'is-invalid': errors.indicativo_comissao
																		}" class="form-control" placeholder="" v-model="indicativo_comissao">
																	<option value="NAO">Não</option>
																	<option value="SIM">Sim</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.indicativo_comissao">
																	<p>{{ errors.indicativo_comissao }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end indicativo_comissao -->

														<!-- comissao -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-comissao">Valor
																	Comissão</label>
																<money v-model="comissao"
																	class="form-control indicativo_comissao"
																	v-bind="valueMaskD3" maxlength="11"
																	v-bind:class="{ 'is-invalid': errors.comissao }">
																</money>
																<div class="invalid-feedback" v-if="errors.comissao">
																	<p>{{ errors.comissao }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end comissao -->

														<!-- valor_taxa_contrato -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_taxa_contrato">Valor Taxa
																	Contrato</label>
																<money v-model="valor_taxa_contrato"
																	class="form-control" v-bind="SevenMoney"
																	maxlength="14"></money>
																<div class="invalid-feedback"
																	v-if="errors.valor_taxa_contrato">
																	<p>{{ errors.valor_taxa_contrato }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end valor_taxa_contrato -->

														<!-- valor_total_financiamento -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_total_financiamento">Valor Total do
																	Financiamento</label>
																<money v-model="valor_total_financiamento"
																	class="form-control" v-bind="SevenMoney" required
																	maxlength="17"></money>
																<div class="invalid-feedback"
																	v-if="errors.valor_total_financiamento">
																	<p>
																		{{ errors.valor_total_financiamento[0] }}
																	</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end valor_total_financiamento -->

														<!-- valor_parcela -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_parcela">Valor da Parcela</label>
																<money v-model="valor_parcela" class="form-control"
																	v-bind="SevenMoney" maxlength="17">
																</money>
																<div class="invalid-feedback"
																	v-if="errors.valor_parcela">
																	<p>{{ errors.valor_parcela }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end valor_parcela -->

														<!-- valor_iof -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_iof">Valor IOF</label>
																<money v-model="valor_iof" class="form-control"
																	v-bind="SevenMoney" maxlength="17">
																</money>
																<div class="invalid-feedback" v-if="errors.valor_iof">
																	<p>{{ errors.valor_iof }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end valor_iof -->

														<!-- data_vecto_primeira_parcela -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_vecto_primeira_parcela">Dt.
																	Venc. 1ª Parcela</label>
																<input type="date"
																	id="input-data_vecto_primeira_parcela" v-bind:class="{
																		'is-invalid':
																			errors.data_vecto_primeira_parcela
																	}" class="form-control" placeholder="data_vecto_primeira_parcela"
																	v-model="data_vecto_primeira_parcela" />
																<div class="invalid-feedback"
																	v-if="errors.data_vecto_primeira_parcela">
																	<p>
																		{{ errors.data_vecto_primeira_parcela[0] }}
																	</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end data_vecto_primeira_parcela -->

														<!-- data_vecto_ultima_parcela -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_vecto_ultima_parcela">Dt. de
																	Venc. Última Parcela</label>
																<input type="date" id="input-data_vecto_ultima_parcela"
																	v-bind:class="{
																		'is-invalid':
																			errors.data_vecto_ultima_parcela
																	}" class="form-control" placeholder="data_vecto_ultima_parcela" v-model="data_vecto_ultima_parcela" />
																<div class="invalid-feedback"
																	v-if="errors.data_vecto_ultima_parcela">
																	<p>
																		{{ errors.data_vecto_ultima_parcela[0] }}
																	</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-4 -->
														<!-- end data_vecto_ultima_parcela -->

														<!-- data_liberacao_credito -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_liberacao_credito">Data de Liberação
																	do Crédito</label>
																<input type="date" id="input-data_liberacao_credito"
																	v-bind:class="{
																		'is-invalid': errors.data_liberacao_credito
																	}" class="form-control" placeholder="data_liberacao_credito" v-model="data_liberacao_credito" />
																<div class="invalid-feedback"
																	v-if="errors.data_liberacao_credito">
																	<p>{{ errors.data_liberacao_credito }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-4 -->
														<!-- end data_liberacao_credito -->

														<!-- uf_liberacao_credito -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_liberacao_credito">UF de Liberação do
																	Crédito</label>
																<select id="input-uf_liberacao_credito" v-bind:class="{
																	'is-invalid': errors.uf_liberacao_credito
																}" class="form-control" placeholder="UF Placa" v-model="uf_liberacao_credito">
																	<option v-for="item in states">{{
																		item
																	}}</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.uf_liberacao_credito">
																	<p>{{ errors.uf_liberacao_credito }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end uf_liberacao_credito -->

														<!-- municipio_liberacao_credito -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-municipio_liberacao_credito">Município de
																	Liberação do Crédito</label>
																<v-select label="municipio"
																	id="input-municipio_liberacao_credito"
																	:options="municipios_lb"
																	:reduce="municipio => municipio.municipio"
																	:value="selected_municipio_liberacao_credito"
																	v-model="municipio_liberacao_credito"
																	@input="settedMunicipioCredito" v-bind:class="{
																		'is-invalid':
																			errors.municipio_liberacao_credito
																	}">
																</v-select>
																<div class="invalid-feedback"
																	v-if="errors.municipio_liberacao_credito">
																	<p>
																		{{ errors.municipio_liberacao_credito[0] }}
																	</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-6 -->
														<!-- end municipio_liberacao_credito -->

														<!-- indice -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-indice">Indíce</label>
																<input type="text" id="input-indice"
																	v-bind:class="{ 'is-invalid': errors.indice }"
																	class="form-control"
																	placeholder="Índice. Ex: SELIC, IGPM, IPCA, entre outros."
																	v-model="indice" maxlength="10"
																	@input="validarCampos('type_indice', 'indice')"/>
																<div class="invalid-feedback" v-if="errors.indice">
																	<p>{{ errors.indice }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-2 -->
														<!-- end indice -->

														<!-- num_grupo_consorcio -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_grupo_consorcio">Nº Grupo
																	Consórcio</label>
																<input type="text" id="input-num_grupo_consorcio"
																	v-bind:class="{
																		'is-invalid': errors.num_grupo_consorcio
																	}" class="form-control" placeholder="Nº Grupo Consórcio" v-model="num_grupo_consorcio" maxlength="6"
																	@input="validarCampos('type_numContrato', 'num_grupo_consorcio')" />
																<div class="invalid-feedback"
																	v-if="errors.num_grupo_consorcio">
																	<p>{{ errors.num_grupo_consorcio }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-2 -->
														<!-- end num_grupo_consorcio -->

														<!-- num_cota_consorcio -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_cota_consorcio">Nº Cota
																	Consórcio</label>
																<input type="tel" id="input-num_cota_consorcio"
																	v-bind:class="{
																		'is-invalid': errors.num_cota_consorcio
																	}" class="form-control" placeholder="Nº Cota Consórcio" v-model="num_cota_consorcio" required="false"
																	maxlength="6"
																	@input="validarCampos('type_numContrato', 'num_cota_consorcio')" />
																<div class="invalid-feedback"
																	v-if="errors.num_cota_consorcio">
																	<p>{{ errors.num_cota_consorcio }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-2 -->
														<!-- end num_cota_consorcio -->

														<!-- num_aditivo -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_aditivo">Nº
																	Aditivo</label>
																<input type="text" id="input-num_aditivo" v-bind:class="{
																	'is-invalid': errors.num_aditivo
																}" class="form-control" placeholder="Nº Aditivo" v-model="num_aditivo" maxlength="20" :disabled="tipo_operacao === '1' || tipo_operacao === '2' ||
																	tipo_operacao === 1 || tipo_operacao === 2" @input="validarCampos('type_numContrato', 'num_aditivo')" />
																<div class="invalid-feedback" v-if="errors.num_aditivo">
																	<p>{{ errors.num_aditivo }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end num_aditivo -->

														<!-- data_aditivo -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_aditivo">Data Aditivo</label>
																<input type="date" id="input-data_aditivo" v-bind:class="{
																	'is-invalid': errors.data_aditivo
																}" class="form-control" placeholder="data_aditivo" v-model="data_aditivo" :disabled="tipo_operacao === '1' || tipo_operacao === '2' ||
																	tipo_operacao === 1 || tipo_operacao === 2" />
																<div class="invalid-feedback"
																	v-if="errors.data_aditivo">
																	<p>{{ errors.data_aditivo }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end data_aditivo -->
													</div>
												</div>
												<a href="javascript:void(0);" @click="changeStep(1)"
													class="btn-previous">Anterior</a>
												<a href="javascript:void(0);" @click="changeStep(3)"
													class="btn-next">Próximo</a>
											</fieldset>
											<!-- end dados financeiros -->

											<!-- agente -->
											<fieldset v-if="step == 4">
												<div class="form-card">
													<div class="row form-distance">
														<!-- nome_agente -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_agente">Nome do Agente</label>
																<input type="text" id="input-nome_agente" v-bind:class="{
																	'is-invalid': errors.nome_agente
																}" class="form-control" placeholder="Nome do Agente" v-model="nome_agente" maxlength="40" />
																<div class="invalid-feedback" v-if="errors.nome_agente">
																	<p>{{ errors.nome_agente }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-6 -->
														<!-- end nome_agente -->

														<!-- cnpj_agente -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cnpj_agente">CNPJ do Agente</label>
																<input type="text" id="input-cnpj_agente" v-bind:class="{
																	'is-invalid': errors.cnpj_agente
																}" class="form-control" placeholder="CNPJ do Agente" v-model="cnpj_agente"
																	v-mask="'##.###.###/####-##'" maxlength="18" />
																<div class="invalid-feedback" v-if="errors.cnpj_agente">
																	<p>{{ errors.cnpj_agente }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-6 -->
														<!-- end cnpj_agente -->

														<!--codigo agente-->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-codigo_agente">Código do Agente</label>
																<input type="text" id="input-codigo_agente"
																	v-bind:class="{
																		'is-invalid': errors.codigo_agente
																	}" style="cursor: not-allowed;" disabled class="form-control" placeholder="Código do Agente"
																	v-model="codigo_agente" maxlength="10" />
																<div class="invalid-feedback"
																	v-if="errors.codigo_agente">
																	<p>{{ errors.codigo_agente }}</p>
																</div>
															</div>
														</div>
														<!--end codigio agente-->
														<!-- nome_logradouro_agente -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_logradouro_agente">Logradouro</label>
																<input type="text" id="input-nome_logradouro_agente"
																	v-bind:class="{
																		'is-invalid': errors.nome_logradouro_agente
																	}" class="form-control" placeholder="Logradouro" v-model="nome_logradouro_agente" maxlength="30" />
																<div class="invalid-feedback"
																	v-if="errors.nome_logradouro_agente">
																	<p>{{ errors.nome_logradouro_agente }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-6 -->
														<!-- end nome_logradouro_agente -->

														<!-- num_imovel_agente -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_imovel_agente">Nº</label>
																<input type="text" id="input-num_imovel_agente"
																	v-bind:class="{
																		'is-invalid': errors.num_imovel_agente
																	}" class="form-control" placeholder="Nº" v-model="num_imovel_agente" maxlength="5" />
																<div class="invalid-feedback"
																	v-if="errors.num_imovel_agente">
																	<p>{{ errors.num_imovel_agente }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-2 -->
														<!-- end num_imovel_agente -->

														<!-- complemento_imovel_agente -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-complemento_imovel_agente">Complemento</label>
																<input type="text" id="input-complemento_imovel_agente"
																	v-bind:class="{
																		'is-invalid':
																			errors.complemento_imovel_agente
																	}" class="form-control" placeholder="Complemento" v-model="complemento_imovel_agente"
																	maxlength="20" />
																<div class="invalid-feedback"
																	v-if="errors.complemento_imovel_agente">
																	<p>
																		{{ errors.complemento_imovel_agente[0] }}
																	</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-4 -->
														<!-- end complemento_imovel_agente -->

														<!-- bairro_agente -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-bairro_agente">Bairro</label>
																<input type="text" id="input-bairro_agente"
																	v-bind:class="{
																		'is-invalid': errors.bairro_agente
																	}" class="form-control" placeholder="Bairro" v-model="bairro_agente" maxlength="20" />
																<div class="invalid-feedback"
																	v-if="errors.bairro_agente">
																	<p>{{ errors.bairro_agente }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end bairro_agente -->

														<!-- uf_agente -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_agente">UF</label>
																<select id="input-uf_agente" v-bind:class="{
																	'is-invalid': errors.uf_agente
																}" class="form-control" placeholder="UF" v-model="uf_agente" @change="onChangeUf('agente', true)">
																	<option v-for="item in states">{{
																		item
																	}}</option>
																</select>
																<div class="invalid-feedback" v-if="errors.uf_agente">
																	<p>{{ errors.uf_agente }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-2 -->
														<!-- end uf_agente -->

														<!-- nome_municipio_agente -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_municipio_agente">Município</label>
																<v-select label="municipio" @input="setSelected"
																	:options="municipios_ag"
																	:reduce="municipio => municipio.codigo_origem"
																	v-model="municipio_ag" v-bind:class="{
																		'is-invalid': errors.nome_municipio_agente
																	}"></v-select>
																<div class="invalid-feedback d-block"
																	v-if="errors.nome_municipio_agente">
																	<p>{{ errors.nome_municipio_agente }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-4 -->
														<!-- end nome_municipio_agente -->

														<!-- cep_agente -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cep_agente">CEP</label>
																<input type="text" id="input-cep_agente" v-bind:class="{
																	'is-invalid': errors.cep_agente
																}" class="form-control" placeholder="CEP" v-model="cep_agente" v-mask="'#####-###'" maxlength="9" />
																<div class="invalid-feedback" v-if="errors.cep_agente">
																	<p>{{ errors.cep_agente }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end cep_agente -->

														<!-- ddd_agente -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ddd_agente">DDD</label>
																<input type="tel" id="input-ddd_agente" v-bind:class="{
																	'is-invalid': errors.ddd_agente
																}" class="form-control" placeholder="DDD" v-model="ddd_agente" maxlength="4" />
																<div class="invalid-feedback" v-if="errors.ddd_agente">
																	<p>{{ errors.ddd_agente }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-2 -->
														<!-- end ddd_agente -->

														<!-- telefone_agente -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-telefone_agente">Telefone</label>
																<div v-if="this.telefone_agente.length != 9 ||
																	this.telefone_agente.length != 10
																">
																	<input type="text" id="input-telefone_agente"
																		v-bind:class="{
																			'is-invalid': errors.telefone_agente
																		}" class="form-control" placeholder="Telefone" v-model="telefone_agente" maxlength="10" />
																	<div v-if="this.telefone_agente.length == 8">
																		<input type="hidden" id="input-telefone_agente"
																			disabled="true" v-bind:class="{
																				'is-invalid': errors.telefone_agente
																			}" class="form-control" placeholder="Telefone" v-model="telefone_agente" v-mask="'####-####'"
																			maxlength="10" />
																	</div>
																	<div v-if="this.telefone_agente.length == 10">
																		<input type="hidden" id="input-telefone_agente"
																			disabled="true" v-bind:class="{
																				'is-invalid': errors.telefone_agente
																			}" class="form-control" placeholder="Telefone" v-model="telefone_agente" v-mask="'#####-####'"
																			maxlength="10" />
																	</div>
																</div>
																<div class="invalid-feedback"
																	v-if="errors.telefone_agente">
																	<p>{{ errors.telefone_agente }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end telefone_agente -->
													</div>
												</div>
												<a href="javascript:void(0);" @click="changeStep(2)"
													class="btn-previous">Anterior</a>
												<a href="javascript:void(0);" @click="changeStep(4)"
													class="btn-next">Próximo</a>
											</fieldset>
											<!-- end agente -->

											<!-- comprador -->
											<fieldset v-if="step == 3">
												<div class="form-card">
													<div class="row form-distance" style="margin-top:-15px">
														<!-- cpf_cnpj_devedor -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cpf_cnpj_devedor">CPF / CNPJ</label>
																<div
																	v-if="this.cpf_cnpj_devedor.length != 14 || this.cpf_cnpj_devedor.length != 18">
																	<input type="text" id="input-cpf_cnpj_devedor"
																		class="form-control" placeholder="CPF / CNPJ"
																		v-model="cpf_cnpj_devedor" maxlength="18"
																		v-bind:class="{ 'is-invalid': errorMessages.cpf_cnpj_devedor || errors.cpf_cnpj_devedor }"
																		@input="validateCpfCnpj(cpf_cnpj_devedor)"
																		@blur="validateCpfCnpj($event.target.value)"
																		@keydown.space.prevent="preventSpace">
																	<div class="invalid-feedback"
																		v-if="errorMessages.cpf_cnpj_devedor">
																		<p>{{ errorMessages.cpf_cnpj_devedor }}</p>
																	</div>
																	<div v-if="this.cpf_cnpj_devedor.length == 10">
																		<input type="hidden" id="input-cpf_cnpj_devedor"
																			disabled="true" class="form-control"
																			placeholder="CPF / CNPJ"
																			v-model="cpf_cnpj_devedor"
																			v-mask="'###.###.###-##'" maxlength="18"
																			v-bind:class="{ 'is-invalid': errorMessages.cpf_cnpj_devedor || errors.cpf_cnpj_devedor }"
																			@input="validateCpfCnpj(cpf_cnpj_devedor)"
																			@blur="validateCpfCnpj($event.target.value)"
																			@keydown.space.prevent="preventSpace">
																		<div class="invalid-feedback"
																			v-if="errorMessages.cpf_cnpj_devedor">
																			<p>{{ errorMessages.cpf_cnpj_devedor }}</p>
																		</div>
																	</div>
																	<div v-if="this.cpf_cnpj_devedor.length == 16">
																		<input type="hidden" id="input-cpf_cnpj_devedor"
																			disabled="true" class="form-control"
																			placeholder="CPF / CNPJ"
																			v-model="cpf_cnpj_devedor"
																			v-mask="'##.###.###/####-##'" maxlength="18"
																			v-bind:class="{ 'is-invalid': errorMessages.cpf_cnpj_devedor || errors.cpf_cnpj_devedor }"
																			@input="validateCpfCnpj(cpf_cnpj_devedor)"
																			@blur="validateCpfCnpj($event.target.value)"
																			@keydown.space.prevent="preventSpace">
																		<div class="invalid-feedback"
																			v-if="errorMessages.cpf_cnpj_devedor">
																			<p>{{ errorMessages.cpf_cnpj_devedor }}</p>
																		</div>
																	</div>
																</div>
																<div class="invalid-feedback"
																	v-if="errors.cpf_cnpj_devedor">
																	<p>{{ errors.cpf_cnpj_devedor }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-6 -->
														<!-- end cpf_cnpj_devedor -->

														<!-- nome_devedor -->
														<div class="col-md-5">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_devedor">Nome</label>
																<input type="text" id="input-nome_devedor" v-bind:class="{
																	'is-invalid': errors.nome_devedor
																}" class="form-control" placeholder="Nome" v-model="nome_devedor" maxlength="50"
																	@input="validarCampos('type_lyrics', 'nome_devedor')" />
																<div class="invalid-feedback"
																	v-if="errors.nome_devedor">
																	<p>{{ errors.nome_devedor }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-6 -->
														<!-- end nome_devedor -->

														<!-- ddd_devedor -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ddd_devedor">DDD</label>
																<input type="tel" id="input-ddd_devedor" v-bind:class="{
																	'is-invalid': errors.ddd_devedor
																}" class="form-control" placeholder="DDD" v-model="ddd_devedor" maxlength="2"
																	@input="validarCampos('type_numbers', 'ddd_devedor')" />
																<div class="invalid-feedback" v-if="errors.ddd_devedor">
																	<p>{{ errors.ddd_devedor }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-2 -->
														<!-- end ddd_devedor -->

														<!-- telefone_devedor -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-telefone_devedor">Telefone</label>
																<div v-if="this.telefone_devedor.length != 9 ||
																	this.telefone_devedor.length != 10
																">
																	<input type="text" id="input-telefone_devedor"
																		v-bind:class="{
																			'is-invalid': errors.telefone_devedor
																		}" class="form-control" placeholder="Telefone" v-model="telefone_devedor" maxlength="10"
																		@input="validarCampos('type_mask', 'telefone_devedor')" />

																	<div v-if="this.telefone_devedor.length == 8">
																		<input type="hidden" id="input-telefone_devedor"
																			disabled="true" v-bind:class="{
																				'is-invalid': errors.telefone_devedor
																			}" class="form-control" placeholder="Telefone" v-model="telefone_devedor" v-mask="'####-####'"
																			maxlength="10"
																			@input="validarCampos('type_mask', 'telefone_devedor')" />
																	</div>
																	<div v-if="this.telefone_devedor.length == 10">
																		<input type="hidden" id="input-telefone_devedor"
																			disabled="true" v-bind:class="{
																				'is-invalid': errors.telefone_devedor
																			}" class="form-control" placeholder="Telefone" v-model="telefone_devedor" v-mask="'#####-####'"
																			maxlength="10"
																			@input="validarCampos('type_mask', 'telefone_devedor')" />
																	</div>
																</div>
																<div class="invalid-feedback"
																	v-if="errors.telefone_devedor">
																	<p>{{ errors.telefone_devedor }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end telefone_devedor -->

														<!-- cep_devedor -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cep_devedor">CEP</label>
																<input type="text" id="input-cep_devedor"
																	v-bind:class="{ 'is-invalid': errors.cep_devedor }"
																	class="form-control" placeholder="CEP"
																	v-model="cep_devedor" v-mask="'#####-###'"
																	required="true" maxlength="9" @blur="verificarCEP"
																	@input="validarCampos('type_mask', 'cep_devedor')">
																<div class="invalid-feedback" v-if="errors.cep_devedor">
																	<p>{{ errors.cep_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end cep_devedor -->

														<!-- uf_devedor -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_devedor">UF</label>
																<select id="input-uf_devedor"
																	v-bind:class="{ 'is-invalid': errors.uf_devedor }"
																	class="form-control" placeholder="UF"
																	v-model="uf_devedor" required="true"
																	@change="onChangeUf('devedor', true)"
																	:disabled="ufBloqueado">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div class="invalid-feedback" v-if="errors.uf_devedor">
																	<p>{{ errors.uf_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end uf_devedor -->

														<!-- nome_municipio_devedor -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_municipio_devedor">Município</label>
																<!--Aplicado um if/else para caso o cep nao seja encontrado abra o campo para digitar
																o municipio  -->
																<template v-if="!cepValido">
																	<!-- Digitar manualmente -->
																	<input type="text" id="input_municipio_dev_manual"
																		v-bind:class="{ 'is-invalid': errors.nome_municipio_devedor }"
																		class="form-control municipio_dev_manual"
																		placeholder="Insira a cidade Manualmente"
																		v-model="municipio_dev_manual" required="true"
																		maxlength="60"
																		@input="validarCampos('type_lyrics', 'municipio_dev_manual')">
																</template>
																<!-- fim -->
																<template v-else>
																	<!-- Vai ser preenchido manualmente pela API -->
																	<v-select id="mySelect" label="municipio"
																		@input="setSelectedDevedor"
																		:options="municipios_dev"
																		:reduce="municipio => municipio.codigo_origem"
																		v-bind:class="{ 'is-invalid': errors.nome_municipio_devedor }"
																		v-model="municipio_dev"
																		:disabled="municipioBloqueado"></v-select>
																</template>
																<!-- Fim -->
																<div class="invalid-feedback d-block"
																	v-if="errors.nome_municipio_devedor">
																	<p>{{ errors.nome_municipio_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end nome_municipio_devedor -->

														<!-- nome_logradouro_devedor -->
														<div class="col-md-5">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_logradouro_devedor">Logradouro</label>
																<input type="text" id="input-nome_logradouro_devedor"
																	v-bind:class="{
																		'is-invalid': errors.nome_logradouro_devedor
																	}" class="form-control" placeholder="Logradouro" v-model="nome_logradouro_devedor" maxlength="100"
																	@input="validarCampos('type_lyrics', 'nome_logradouro_devedor')" />
																<div class="invalid-feedback"
																	v-if="errors.nome_logradouro_devedor">
																	<p>{{ errors.nome_logradouro_devedor }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-6 -->
														<!-- end nome_logradouro_devedor -->

														<!-- num_imovel_devedor -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_imovel_devedor">Nº</label>
																<input type="text" id="input-num_imovel_devedor"
																	v-bind:class="{ 'is-invalid': errors.num_imovel_devedor }"
																	class="form-control" placeholder="Nº"
																	v-model="num_imovel_devedor" required="true"
																	maxlength="5">
																<div class="invalid-feedback"
																	v-if="errors.num_imovel_devedor">
																	<p>{{ errors.num_imovel_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end num_imovel_devedor -->

														<!-- bairro_devedor -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-bairro_devedor">Bairro</label>
																<input type="text" id="input-bairro_devedor"
																	v-bind:class="{
																		'is-invalid': errors.bairro_devedor
																	}" class="form-control" placeholder="Bairro" v-model="bairro_devedor" maxlength="100"
																	@input="validarCampos('type_lyrics', 'bairro_devedor')" />
																<div class="invalid-feedback"
																	v-if="errors.bairro_devedor">
																	<p>{{ errors.bairro_devedor }}</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-3 -->
														<!-- end bairro_devedor -->

														<!-- complemento_imovel_devedor -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-complemento_imovel_devedor">Complemento</label>
																<input type="text" id="input-complemento_imovel_devedor"
																	v-bind:class="{
																		'is-invalid':
																			errors.complemento_imovel_devedor
																	}" class="form-control" placeholder="Complemento" v-model="complemento_imovel_devedor"
																	maxlength="20" />
																<div class="invalid-feedback"
																	v-if="errors.complemento_imovel_devedor">
																	<p>
																		{{ errors.complemento_imovel_devedor[0] }}
																	</p>
																</div>
															</div>
															<!-- form group -->
														</div>
														<!-- col-md-4 -->
														<!-- end complemento_imovel_devedor -->

														<!-- codigo SERPRO-->
														<div class="col-md-2">
															<div class="form-group">
																<!-- <div class="form-group" v-if="!cepValido"></div> -->
																<label class="form-control-label"
																	for="input_cod_serpro_devedor">Cód SERPRO</label>
																<input type="text" id="input_cod_serpro_devedor"
																	v-bind:class="{ 'is-invalid': errors.cod_serpro_devedor }"
																	class="form-control" placeholder="Código"
																	v-model="cod_serpro_devedor" required="true"
																	maxlength="4" :disabled="serproBloqueado"
																	@input="validarCampos('type_numbers', 'cod_serpro_devedor')">
																<div class="invalid-feedback" v-if="errors.cep_devedor">
																	<p>{{ errors.cod_serpro_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end codigo SERPRO -->

														<!-- arquivo contrato -->
														<div class="col-md-9">
															<div>
																<label>PDF do
																	Contrato</label>
																<div
																	v-if="arquivo != '' && arquivo != 'null' && arquivo != null">
																	<div style="margin-bottom: 15px;">
																		<div class="form-group-contract">
																			<span class="span-text">Tamanho máximo
																				permitido: 5mb</span>
																			<br />
																			<label class="import-contract custom-label"
																				for="attach">
																				Anexar novo PDF
																			</label>
																			<input id="attach" ref="attach"
																				accept="application/pdf"
																				class="form-control" lang="pt"
																				name="attach" type="file"
																				v-on:change="handleFileUpload()"
																				style="display: none;" />
																			<span
																				v-if="attach !== '' ? attach : arquivo"
																				class="span-name-arq">
																				Arquivo selecionado: {{ attach !== '' ?
																					attach.name : "Contrato " +
																					this.getData('chassi') + ".pdf" }}
																			</span>
																			<span v-else class="span-name-arq">Nenhum
																				arquivo selecionado</span>
																		</div>
																	</div>
																	<a :href="'/portal/contratos/' + url_estado + '/' + this.getData('id') + '/download'"
																		target="_blank"
																		class="btn btn-primary icon-left btn-icon"
																		style="margin-bottom: 20px;">
																		<i class="fas fa-download"></i> Download - {{
																			"Contrato " + this.getData('chassi') +
																			".pdf" }}</a>
																</div>
																<div v-else>
																	<div class="col-md-9">
																		<div class="form-group-contract">
																			<span class="span-text">Tamanho máximo
																				permitido: 5mb</span>
																			<br />
																			<label class="import-contract custom-label"
																				for="attach">
																				Anexe o PDF do Contrato
																			</label>
																			<input id="attach" ref="attach"
																				accept="application/pdf"
																				class="form-control" lang="pt"
																				name="attach" type="file"
																				v-on:change="handleFileUpload()"
																				style="display: none;" />
																			<span v-if="attach"
																				class="span-name-arq">Arquivo
																				selecionado:
																				{{ attach.name
																				}}</span>
																			<span v-else class="span-name-arq">Nenhum
																				arquivo selecionado</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<!-- end arquivo contrato -->
													</div>
												</div>
												<a href="javascript:void(0);" @click="changeStep(2)"
													class="btn-previous">Anterior</a>
												<a href="javascript:void(0);"
													@click="updateData(); gravaInfoMunicipio()"
													class="btn-next btn-enviar">ENVIAR
													CONTRATO</a>
											</fieldset>
											<!-- end comprador -->
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- card -->
		</div>
		<!-- col-12 -->
		<vue-loading color="#ec2434" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div>
	<!-- row -->
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Money from "v-money";
import VMask from "v-mask";
import { fieldNames } from '../fieldNames/fieldNames';
import { validateCpfCnpj, validateCampo } from '../../../validarCampos/validations';

export default {
	props: ['contrato', 'permissions', 'user', 'instituicao', 'estado'],
	data() {
		return {
			userGpEcon: JSON.parse(this.user),
			instituicaoSelecionada: JSON.parse(this.instituicao),
			titleform: 'Preencha as informações abaixo para enviar o Contrato Detran ' + this.estado,
			selected_municipio_liberacao_credito: this.getData(
				"municipio_liberacao_credito"
			),
			cod_municipio_liberacao_credito: this.getData(
				"municipio_liberacao_credito"
			),
			municipio_liberacao_credito: this.getData("municipio_liberacao_credito"),
			url_estado: "BA",
			tipo_operacao: 2,
			sequencia_contrato: this.getData("sequencia_contrato"),
			num_contrato_origem: this.getData("num_contrato_origem"),
			num_aditivo_origem: this.getData("num_aditivo_origem"),
			chassi: this.getData("chassi"),
			remarcacao: this.getData("remarcacao"),
			indicador_novo_usado: this.getData('indicador_novo_usado'),
			uf_licenciamento: this.getData("uf_licenciamento"),
			uf_placa: this.getData("uf_placa") == null || this.getData("uf_placa") == 'null' ? '' : this.getData("uf_placa"),
			placa: this.getData("placa"),
			renavam: this.getData("renavam"),
			ano_fabricacao: this.getData("ano_fabricacao"),
			ano_modelo: this.getData("ano_modelo"),
			num_contrato: this.getData("num_contrato"),
			data_contrato: this.validateDate(this.getData("data_contrato")),
			data_contato: this.getData("num_contrato"),
			qtd_parcelas: this.getData("qtd_parcelas"),
			num_gravame: this.getData("num_gravame"),
			tipo_gravame: this.getData("tipo_gravame"),
			taxa_juro_mes: this.getData("taxa_juro_mes"),
			taxa_juro_ano: this.getData("taxa_juro_ano"),
			taxa_juro_multa: this.getData("taxa_juro_multa"),
			taxa_mora_dia: this.getData("taxa_mora_dia"),
			taxa_multa: this.getData("taxa_multa"),
			taxa_mora: this.getData("taxa_mora"),
			indicativo_penalidade: this.getData("indicativo_penalidade"),
			penalidade: this.getData("penalidade"),
			indicativo_comissao: this.getData("indicativo_comissao"),
			comissao: this.getData("comissao"),
			valor_taxa_contrato: this.getData("valor_taxa_contrato"),
			valor_total_financiamento: this.getData("valor_total_financiamento"),
			valor_iof: this.getData("valor_iof"),
			valor_parcela: this.getData("valor_parcela"),
			data_vecto_primeira_parcela: this.validateDate(
				this.getData("data_vecto_primeira_parcela")
			),
			data_vecto_ultima_parcela: this.validateDate(
				this.getData("data_vecto_ultima_parcela")
			),
			data_liberacao_credito: this.validateDate(
				this.getData("data_liberacao_credito")
			),
			uf_liberacao_credito: this.getData("uf_liberacao_credito"),
			indice: this.getData("indice"),
			num_grupo_consorcio: this.getData("num_grupo_consorcio"),
			num_cota_consorcio: this.getData("num_cota_consorcio"),
			num_aditivo: this.getData("num_aditivo"),
			data_aditivo: this.getData("data_aditivo") == null || this.getData("data_aditivo") == 'null' ? '' : this.getData("data_aditivo"),
			cod_serpro_devedor: this.getData("nome_municipio_devedor"),
			cepValido: false,
			serproBloqueado: true,
			ufBloqueado: false,
			municipioBloqueado: false,
			cpf_cnpj_devedor: this.getData("cpf_cnpj_devedor"),
			nome_devedor: this.getData("nome_devedor"),
			nome_logradouro_devedor: this.getData("nome_logradouro_devedor"),
			num_imovel_devedor: this.getData("num_imovel_devedor"),
			complemento_imovel_devedor:
				this.getData("complemento_imovel_devedor") == null ||
					this.getData("complemento_imovel_devedor") == "null"
					? ""
					: this.getData("complemento_imovel_devedor"),
			bairro_devedor: this.getData("bairro_devedor"),
			nome_municipio_devedor: this.getData("nome_municipio_devedor"),
			municipio_dev: "",
			uf_devedor: this.getData("uf_devedor"),
			cep_devedor: this.getData("cep_devedor"),
			ddd_devedor: this.getData("ddd_devedor"),
			telefone_devedor: this.getData("telefone_devedor"),
			states: [
				"AC",
				"AL",
				"AM",
				"AP",
				"BA",
				"CE",
				"DF",
				"ES",
				"GO",
				"MA",
				"MT",
				"MS",
				"MG",
				"PA",
				"PB",
				"PR",
				"PE",
				"PI",
				"RJ",
				"RN",
				"RO",
				"RS",
				"RR",
				"SC",
				"SE",
				"SP",
				"TO"
			],
			errors: [],
			step: 1,
			message: "",
			loading: false,
			fullPage: true,
			municipios: [],
			municipios_ag: [],
			municipios_dev: [],
			municipio_dev_manual: this.getData("nome_municipio_devedor"),
			cor_id: this.getData("cor_id"),
			tipo_id: this.getData("tipo_id"),
			marca_id: this.getData("marca_id"),
			modelo_id: this.getData("modelo_id"),
			observacao: this.getData("observacao"),
			attach: "",
			arquivo: this.getData("arquivo"),
			tipos: [],
			marcas: [],
			modelos: [],
			cores: [],
			valueMaskD3: {
				decimal: ',',
				thousands: '.',
				prefix: '',
				precision: 3,
				masked: false
			},
			percentmoney: {
				decimal: ",",
				thousands: ".",
				prefix: "",
				suffix: " %",
				precision: 3,
				masked: false
			},
			money: {
				decimal: ",",
				thousands: ".",
				prefix: "R$ ",
				suffix: "",
				precision: 3,
				masked: false
			},
			SevenMoney: {
				decimal: ",",
				thousands: ".",
				prefix: "R$ ",
				suffix: "",
				precision: 2,
				masked: false
			},
			errorMessages: {},
		};
	},
	mounted() {
		this.onChangeUf("devedor");
		this.loadCores();
		this.loadTipos();
		this.b64decode(this.arquivo);

	},
	computed: {

	},
	watch: {
		uf_liberacao_credito: {
			immediate: true,
			handler(newUF, oldUF) {
				let changeUF;
				if (newUF && oldUF) changeUF = oldUF !== newUF;
				this.searchDetranCidades(newUF, changeUF);
			}
		},
		indicador_novo_usado() {
			this.atualizarRemarcacao();
		},
		tipo_operacao() {
			this.atualizarDadosAditivo();
		},
	},
	methods: {
		validarCampos(type, model) {
			this[model] = validateCampo(type, this[model])
		},
		validateCpfCnpj(value) {
			this.errorMessages.cpf_cnpj_devedor = validateCpfCnpj(value);
			this.cpf_cnpj_devedor = this.cpf_cnpj_devedor.replace(/[^\d\/\.\-]/g, '');
		},

		atualizarRemarcacao() {
			if (this.indicador_novo_usado === 'N') {
				this.remarcacao = '2';
				this.placa = '';
				this.renavam = '';
				this.uf_placa = '';
			}
			else {
				this.remarcacao = '';
			}
		},
		atualizarDadosAditivo() {
			if (this.tipo_operacao === '1' || this.tipo_operacao === '2') {
				this.num_aditivo_origem = '';
				this.num_aditivo = '';
				this.data_aditivo = '';
			}
		},
		settedMunicipioCredito(item) {
			let _this = this;
			_this.municipio_liberacao_credito = item;
		},

		searchDetranCidades(estado, changeUF = false) {
			let _this = this;

			axios
				.get("/api/portal/detrancidades/search/" + estado)
				.then(res => {
					_this.municipios_lb = res.data;

					if (changeUF !== true) {
						//forma mais rapida de mostrar o nome Município de Liberação do Crédito
						let codMunicipioLiberacaoCredito = this.getData(
							"municipio_liberacao_credito"
						);

						_this.municipios_lb.forEach(function (nome, i) {
							if (nome.codigo_origem === codMunicipioLiberacaoCredito) {
								_this.municipio_liberacao_credito = nome.municipio;
							}
						});
					} else {
						_this.municipio_liberacao_credito = "";
						_this.cod_municipio_liberacao_credito = "";
					}
				})
				.catch(err => {
					console.warn(err);
				});
		},

		/*funcao que adiciona zeros a esqueda*/
		addLeadingZeros(number) {
			let leadingZeros = "";

			if (number !== "null" && number !== "undefined" && number !== "") {
				if (number.length === 1) leadingZeros = "000" + number;
				if (number.length === 2) leadingZeros = "00" + number;
				if (number.length === 3) leadingZeros = "0" + number;

				return leadingZeros ? leadingZeros : number;
			}
			//return number;
		},

		/*pega o estada que esta aparecendo na url*/
		getData(key) {
			return JSON.parse(this.contrato)[key] == null || JSON.parse(this.contrato)[key] == "null" ? "" : JSON.parse(this.contrato)[key];
		},

		loadCores() {
			axios.get("/portal/cores").then(response => {
				this.cores = response.data;
			});
		},

		getMarcas(e) {
			if (e == null || e == "" || e == []) {
				this.marcas = [];
				this.modelos = [];
				this.cor_id = "";
				this.marca_id = "";
				this.tipo_id = "";
				this.modelo_id = "";
			}
			let tipo_id = e;

			if ("" !== tipo_id) {
				axios
					.get(`/portal/marcas/${tipo_id}`)
					.then(response => {
						this.marcas = response.data;
					})
					.catch(error => { });
			} else {
				this.marcas = [];
				this.modelos = [];
				this.cores = [];
				this.cor_id = "";
			}
		},

		getModelos(e) {
			if (e == null) {
				this.cores = [];
				this.modelos = [];
				this.marcas = [];
				this.cor_id = "";
				this.marca_id = "";
				this.modelo_id = "";
				this.tipo_id = "";
			}
			let marca_id = e;

			if ("" !== marca_id) {
				axios
					.get(`/portal/modelos/${marca_id}`)
					.then(response => {
						this.modelos = response.data;
						if (this.modelos.id != "") {
							this.loadCores();
							this.cor_id = this.cores.id;
						} else {
							this.cores = [];
							this.cor_id = "";
						}
					})
					.catch(error => { });
			} else {
				this.modelos = [];
				this.cores = [];
				this.cor_id = "";
			}
		},

		loadTipos() {
			let _this = this;
			_this.loading = true;
			_this.tipos = [];
			axios
				.get("/portal/tipos")
				.then(res => {
					_this.tipos = _this.tipos.concat(res.data);
					_this.loading = false;
					this.loadMarcas(_this.tipos.id);
				})
				.catch(err => {
					_this.loading = false;
				});
		},
		// Função para verificar o cep e realizar os devidos bloqueios de campos e preenchimentos
		async verificarCEP() {
			let cep = this.cep_devedor.replace('-', '');
			try {
				const response = await axios.get(`/portal/consultacep/${cep}`);
				const data = response.data;
				// Se o CEP foi encontrado, preenche os campos de endereço e bloqueia os campo SERPRO,UF e Municipio
				this.cod_serpro_devedor = data.codigo_serpro != "" ? data.codigo_serpro : "";
				this.cepValido = true;
				this.serproBloqueado = data.codigo_serpro != "" ? true : false;
				this.nome_logradouro_devedor = data.logradouro;
				this.bairro_devedor = data.bairro;
				this.municipio_dev = data.localidade;
				this.uf_devedor = data.uf;
				this.ufBloqueado = true;
				this.municipioBloqueado = true;
				this.dadosApi = data.codigo_serpro != "" ? true : false;

			} catch (error) {
				this.cod_serpro_devedor = ''
				this.cepValido = false;
				this.serproBloqueado = false;
				this.nome_logradouro_devedor = '';
				this.bairro_devedor = '';
				this.municipio_dev = '';
				this.uf_devedor = '';
				this.ufBloqueado = false;
				this.municipioBloqueado = false;
			}
		},
		// Fim da Função

		// Função para gravar um cep que nao exista na lista da api, onde sera inserido
		// Codigo SerPro, Cidade e o Estado
		gravaInfoMunicipio() {
			let nomeMunDev = "";

			if (this.municipio_dev_manual) { // Se o campo de município foi preenchido manualmente
				nomeMunDev = this.municipio_dev_manual;
			} else { // Caso contrário, encontra o nome do município selecionado no v-select
				let arr = this.municipios_dev;
				for (let i = 0; i < arr.length; i++) {
					if (arr[i]['codigo_origem'] === this.municipio_dev) {
						nomeMunDev = arr[i]['municipio'];
						break;
					}
				}
			}

			let formData = new FormData();

			formData.append("uf", this.uf_devedor);
			formData.append("municipio", nomeMunDev.toUpperCase());
			formData.append("codigo_origem", this.cod_serpro_devedor);

			axios.post("/portal/consultacep/store", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
				console.log(res)
			}).catch((err) => {
				console.log(err);
			});
		},
		// Fim
		handleFileUpload() {

			if (this.$refs.attach.files[0].size > 5120 * 5120) {
				this.$swal({
					title: "Erro",
					html: "Arquivo precisa ter no máximo 5mb de tamanho!",
					icon: "error"
				});

				return;
			} else {
				this.attach = this.$refs.attach.files[0];
			}
		},
		updateData() {
			let _this = this;
			_this.errors = [];
			_this.message = "";
			_this.loading = true;
			_this.cpf_cnpj_devedor = _this.converteCPFeCnpj(_this.cpf_cnpj_devedor);
			_this.cep_devedor = _this.converteCep(_this.cep_devedor);
			_this.telefone_devedor = _this.converteTelefone(_this.telefone_devedor);

			let formData = new FormData();

			formData.append("tipo_operacao", _this.tipo_operacao);
			formData.append("sequencia_contrato", _this.sequencia_contrato);
			formData.append("num_contrato_origem", _this.num_contrato_origem);
			formData.append("num_aditivo_origem", _this.num_aditivo_origem);
			formData.append("chassi", _this.chassi);
			formData.append("remarcacao", _this.remarcacao);
			formData.append("indicador_novo_usado", _this.indicador_novo_usado);
			formData.append("uf_licenciamento", _this.uf_licenciamento);
			formData.append("uf_placa", _this.uf_placa == undefined ? '' : _this.uf_placa);
			formData.append("placa", _this.placa == undefined ? '' : _this.placa);
			formData.append("renavam", _this.renavam);
			formData.append("ano_fabricacao", _this.ano_fabricacao);
			formData.append("ano_modelo", _this.ano_modelo);
			formData.append("num_contrato", _this.num_contrato);
			formData.append(
				"data_contrato",
				_this.data_contrato == ""
					? ""
					: moment(String(_this.data_contrato)).format('YYYY-MM-DD')
			);
			formData.append("data_contato", _this.data_contato);
			formData.append("qtd_parcelas", _this.qtd_parcelas);
			formData.append("num_gravame", _this.num_gravame);
			formData.append("tipo_gravame", _this.tipo_gravame);
			formData.append("taxa_juro_mes", _this.taxa_juro_mes);
			formData.append("taxa_juro_ano", _this.taxa_juro_ano);
			formData.append("taxa_juro_multa", _this.taxa_juro_multa);
			formData.append("taxa_mora_dia", _this.taxa_mora_dia);
			formData.append("taxa_multa", _this.taxa_multa == '0' ? '' : _this.taxa_multa);
			formData.append("taxa_mora", _this.taxa_mora == '0' ? '' : _this.taxa_mora);
			formData.append("indicativo_penalidade", _this.indicativo_penalidade);
			formData.append("penalidade", _this.penalidade);
			formData.append("indicativo_comissao", _this.indicativo_comissao);
			formData.append("comissao", _this.comissao == '0' ? '' : _this.comissao);
			formData.append("valor_taxa_contrato", _this.valor_taxa_contrato);
			formData.append("valor_total_financiamento", _this.valor_total_financiamento);
			formData.append("valor_iof", _this.valor_iof);
			formData.append("valor_parcela", _this.valor_parcela);
			formData.append("data_vecto_primeira_parcela", _this.data_vecto_primeira_parcela == "" ? "" : moment(String(_this.data_vecto_primeira_parcela)).format("YYYY-MM-DD"));
			formData.append("data_vecto_ultima_parcela", _this.data_vecto_ultima_parcela == "" ? "" : moment(String(_this.data_vecto_ultima_parcela)).format('YYYY-MM-DD'));
			formData.append("data_liberacao_credito", _this.data_liberacao_credito == "" ? "" : moment(String(_this.data_liberacao_credito)).format('YYYY-MM-DD'));
			formData.append("uf_liberacao_credito", _this.uf_liberacao_credito);
			formData.append("municipio_liberacao_credito", _this.municipio_liberacao_credito);
			formData.append("indice", _this.indice);
			formData.append("num_grupo_consorcio", _this.num_grupo_consorcio);
			formData.append("num_cota_consorcio", _this.num_cota_consorcio);
			formData.append("num_aditivo", _this.num_aditivo == null ? '' : _this.num_aditivo);
			formData.append("data_aditivo", _this.data_aditivo == "" ? "" : moment(String(_this.data_aditivo)).format('YYYY-MM-DD'));
			formData.append("cpf_cnpj_devedor", _this.cpf_cnpj_devedor);
			formData.append("nome_devedor", _this.nome_devedor);
			formData.append("nome_logradouro_devedor", _this.nome_logradouro_devedor);
			formData.append("num_imovel_devedor", _this.num_imovel_devedor);
			formData.append("complemento_imovel_devedor", _this.complemento_imovel_devedor);
			formData.append("bairro_devedor", _this.bairro_devedor);
			formData.append("nome_municipio_devedor", _this.cod_serpro_devedor);
			formData.append("uf_devedor", _this.uf_devedor);
			formData.append("cep_devedor", _this.cep_devedor);
			formData.append("cod_serpro_devedor", _this.cod_serpro_devedor);
			formData.append("ddd_devedor", _this.ddd_devedor);
			formData.append("telefone_devedor", _this.telefone_devedor);
			formData.append("contrato_arquivo", _this.attach);
			formData.append(
				"tipo_id",
				_this.tipo_id == undefined ? "" : _this.tipo_id
			);
			formData.append(
				"marca_id",
				_this.marca_id == undefined ? "" : _this.marca_id
			);
			formData.append(
				"modelo_id",
				_this.modelo_id == undefined ? "" : _this.modelo_id
			);
			formData.append("cor_id", _this.cor_id == undefined ? "" : _this.cor_id);
			formData.append("observacao", _this.observacao);

			formData.append("isGroupEcon", this.userGpEcon.is_group_economic)
			if (this.userGpEcon.is_group_economic == 'S') {
				formData.append('insituicaoId', _this.instituicaoSelecionada.id)
			}

			formData.append("_method", "PATCH");

			axios.post("/portal/contratodetranba/" + this.getData("id"), formData, {
				headers: { "Content-Type": "multipart/form-data" }
			})
				.then(res => {
					_this.loading = false;
					_this.resetForm();

					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "Contrato cadastrado com sucesso, em breve será enviado ao Detran!",
						icon: "success"
					}).then((res) => {
						// Se o usuário clicar em "Confirmar"
						if (res.status = 200) {
							// Redireciona para a página de destino
							window.location.href = process.env.MIX_URL + 'contratos';
						}
					});
				}).catch((err) => {

					var errors = '';

					_this.loading = false;

					if (err.response.status == 422) {

						this.errors = err.response.data.errors;

						var errorFields = Object.keys(err.response.data.errors).map((key) => {
							// Verifica se o mapeamento existe para o nome da variável
							if (fieldNames.campoObrigatorio.hasOwnProperty(key)) {
								return fieldNames.campoObrigatorio[key];
							} else {
								// Caso o mapeamento não exista, retorna o nome da variável original
								return key;
							}
						}).join(', <br> - ');

						_this.$swal({
							title: "Erro",
							html: `O(s) seguinte(s) campo(s) está(ão) preenchido(s) incorretamente:<br> - ${errorFields}`,
							icon: "error"
						});
					} else {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao cadastrar o contrato. Tente novamente!",
							icon: "error"
						});
					}

				});
		},
		changeStep(option) {
			this.step = option;
		},
		resetForm() {
			this.tipo_operacao = "";
			this.sequencia_contrato = "";
			this.num_contrato_origem = "";
			this.num_aditivo_origem = "";
			this.chassi = "";
			this.remarcacao = "";
			this.uf_placa = "";
			this.placa = "";
			this.renavam = "";
			this.ano_fabricacao = "";
			this.ano_modelo = "";
			this.num_contrato = "";
			this.data_contrato = "";
			this.data_contato = "";
			this.qtd_parcelas = "";
			this.num_gravame = "";
			this.tipo_gravame = "";
			this.taxa_juro_mes = "";
			this.taxa_juro_ano = "";
			this.taxa_juro_multa = "";
			this.taxa_mora_dia = "";
			this.taxa_multa = "";
			this.taxa_mora = "";
			this.indicativo_penalidade = "";
			this.penalidade = "";
			this.indicativo_comissao = "";
			this.comissao = "";
			this.valor_taxa_contrato = "";
			this.valor_total_financiamento = "";
			this.valor_iof = "";
			this.valor_parcela = "";
			this.data_vecto_primeira_parcela = "";
			this.data_vecto_ultima_parcela = "";
			this.data_liberacao_credito = "";
			this.uf_liberacao_credito = "";
			this.municipio_liberacao_credito = "";
			this.indice = "";
			this.num_grupo_consorcio = "";
			this.num_cota_consorcio = "";
			this.num_aditivo = "";
			this.data_aditivo = "";
			this.cpf_cnpj_devedor = "";
			this.nome_devedor = "";
			this.nome_logradouro_devedor = "";
			this.num_imovel_devedor = "";
			this.complemento_imovel_devedor = "";
			this.bairro_devedor = "";
			this.nome_municipio_devedor = "";
			this.uf_devedor = "";
			this.cep_devedor = "";
			this.ddd_devedor = "";
			this.telefone_devedor = "";
		},

		onChangeUf(typeSearch, clickSearch) {
			let _this = this;
			var estado;

			if (typeSearch == "agente") {
				estado = _this.uf_agente;
			} else {
				estado = _this.uf_devedor;
			}

			axios
				.get("/api/portal/detrancidades/search/" + estado)
				.then(res => {
					if (typeSearch == "agente") {
						_this.municipios_ag = res.data;

						if (clickSearch !== true) {
							//forma mais rapida de mostrar o nome do Municipio Agente
							let codMunicipioAgente = this.getData(
								"nome_municipio_agente"
							).toString();
							codMunicipioAgente = this.addLeadingZeros(codMunicipioAgente);
							_this.municipios_ag.forEach(function (nome, i) {
								if (nome.codigo_origem === codMunicipioAgente) {
									_this.municipio_ag = nome.municipio;
								}
							});
						} else {
							_this.municipio_ag = "";
							_this.nome_municipio_agente = "";
						}
					} else {
						_this.municipios_dev = res.data;

						if (clickSearch !== true) {
							//forma mais rapida de mostrar o nome do Municipio Comprador
							let codMunicipioComprador = this.getData("nome_municipio_devedor").toString();
							codMunicipioComprador = this.addLeadingZeros(codMunicipioComprador);

							_this.municipios_dev.forEach(function (nome, i) {
								if (nome.codigo_origem == codMunicipioComprador) {
									_this.municipio_dev = nome.municipio
									_this.municipio_dev_manual = nome.municipio
								}
							});
						} else {
							_this.municipio_dev = "";
							_this.nome_municipio_devedor = "";
						}
					}
				}).catch((err) => {

					_this.$swal({
						title: "Erro",
						text: "Falha ao pesquisar o estao: " + estado + " tente novamente!",
						icon: "error"
					});
				});
		},

		setSelected(item) {
			let _this = this;
			_this.nome_municipio_agente = item;
			//_this.municipio_dev = _this.municipios_dev.municipio
		},

		setSelectedDevedor(item) {
			let _this = this;
			_this.nome_municipio_devedor = item;
			//_this.municipio_dev = _this.municipios_dev.municipio
		},
		validateDate(date) {
			let temp;

			if (date) {
				temp = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
				return String(temp);
			}

			return date;
		},
		b64decode(b64String) {
			if (b64String)
				this.arquivo = atob(b64String.split(',')[1]);
		},
		loadModelos(e) {
			if ("" !== e) {
				axios
					.get(`/portal/modelos/${e}`)
					.then(response => {
						this.modelos = response.data;
					})
					.catch(error => { });
			} else {
				this.marcas = [];
				this.modelos = [];
				this.cores = [];
				this.cor_id = "";
			}
		},
		loadMarcas(e) {
			if ("" !== e) {
				axios
					.get(`/portal/marcas/${e}`)
					.then(response => {
						this.marcas = response.data;
						this.loadModelos(this.marca_id);
					})
					.catch(error => { });
			} else {
				this.marcas = [];
				this.modelos = [];
				this.cores = [];
				this.cor_id = "";
			}
		},
		converteCPFeCnpj(e) {
			if (typeof e == "string") {
				if (e.length == 14) {
					let cpf = e.replace(".", "");
					cpf = cpf.replace(".", "");
					cpf = cpf.replace("-", "");
					let cpfConvert = cpf;
					return cpfConvert;
				} else {
					let cpf = e.replace(".", "");
					cpf = cpf.replace(".", "");
					cpf = cpf.replace("/", "");
					cpf = cpf.replace("-", "");
					let cpfConvert = cpf;
					return cpfConvert;
				}
			}
			return e;
		},
		converteCep(e) {
			if (typeof e == "string") {
				if (e.length == 9 || e.length == 8) {
					//let cep = e.replace('-', '')
					//let cepConvert = parseInt(cep)
					return e.replace("-", "");
				}
			}
			return e;
		},
		converteTelefone(e) {
			if (typeof e == "string") {
				if (e.length == 10 || e.length == 9) {
					let tel = e.replace("-", "");
					let telConvert = parseInt(tel);
					return telConvert;
				}
			}
			return e;
		},
		disableMulta(v, t) {
			if (v == "NAO" && t == "taxa_juro_multa") {
				$(".taxa_juro_multa").prop("disabled", true);
				this.taxa_multa = "";
			} else {
				$(".taxa_juro_multa").prop("disabled", false);
			}
		},
		disableMora(v, t) {
			if (v == "NAO" && t == "taxa_mora_dia") {
				$(".taxa_mora_dia").prop("disabled", true);
				this.taxa_mora = "";
			} else {
				$(".taxa_mora_dia").prop("disabled", false);
			}
		},
		disablePenalidade(v, t) {
			if (v == "NAO" && t == "indicativo_penalidade") {
				$(".penalidade").prop("disabled", true);
				this.penalidade = "";
			} else {
				$(".penalidade").prop("disabled", false);
			}
		},
		disableComissao(v, t) {
			if (v == "NAO" && t == "indicativo_comissao") {
				$(".indicativo_comissao").prop("disabled", true);
				this.comissao = "";
			} else {
				$(".indicativo_comissao").prop("disabled", false);
			}
		}
	},
	componentes: {
		vSelect,
		Money,
		VMask
	}
};
</script>
