<template>
    <div class="row">
      <div class="col">
        <h4>Auditoria</h4>
        <!-- table -->
        <div class="card p-4">
        <!-- Filtro -->
				<div>
					<h5>Filtrar Período Desejado: </h5>
				</div>
				<div class="row">
					<div class="row">
						<div class="col-md-6 p-4">
							<div class="form-group date-custom">
							<datepicker :language="calendarLanguage" v-model="dataInicial" placeholder="Data Inicial" class="btn-filter" />
							</div>
						</div>
						<div class="col-md-6 p-4">
							<div class="form-group date-custom">
							<datepicker :language="calendarLanguage" v-model="dataFinal" placeholder="Data Final" class="btn-filter" />
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<a href="#" class="btn btn-danger col-md-5 col-lg-3 col-sm-6  mt-4 btn-filter export-button" style="height: 30px;"
							@click="carregarLogs()">
							Filtrar
						</a>
						<button class="btn btn-limpar btn-filter col-md-4 col-lg-2 col-sm-5 mt-4 ml-2 " style="border-radius: 10px; height: 30px;" data-toggle="tooltip"
							data-placement="top" title="Limpar Filtro" @click="limparFiltros">
							 Limpar Filtro <i class="fas fa-trash fa-lg"></i>
						</button>
					</div>

				</div>
				<div class="mx-auto">
					<h5>Período: {{ periodoSelecionado }}</h5>
				</div>
        <!-- Filtro -->
          <vue-good-table
              :columns="columns"
              :rows="logs"
              :line-numbers="true"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                position: 'bottom',
                dropdownAllowAll: true,
                perPageDropdown: [10, 30, 50],
                nextLabel: 'Próximo',
                prevLabel: 'Anterior',
                rowsPerPageLabel: '',
                ofLabel: 'de',
                allLabel: 'Todos',
                pageLabel: '',
                perPageDropdownEnabled: true,
                compactMode: true,
              }"
              :search-options="{
                  enabled: true,
                  placeholder: 'Pesquisar',
                }"
              styleClass="vgt-table"
          >
            <template v-slot:table-row="props">
              <span v-if="props.column.field == 'actions'">
                  <div class="text-center">
                    <button title="Visualizar detalhes" class="btn btn-sm btn-outline-secondary" @click="showInfo(props.row.num_contrato,
                    props.row.event, props.row.model_type, props.row.properties)">
                      <i class="fas fa-table"></i>
                    </button>
                  </div>
                </span>
              <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
          </vue-good-table>
        </div>
        <!-- end table -->
        <div v-if="!logs.length && !loading" class="text-center p-3 text-muted">
          <h5>Nenhum log encontrado!</h5>
        </div>
        <div class="text-center p-4 text-muted" v-if="loading">
          <h5>Carregando...</h5>
          <p>Aguarde até que os dados sejam carregados...</p>
        </div>
      </div><!-- col -->

      <!--   modal cadastro de tipos     -->
      <div class="modal fade" id="modalDetailAudit" tabindex="-1" aria-labelledby="modalApiReturnsLabel"
           aria-hidden="true">
        <div class="modal-dialog custom-modal modal-dialog-centered" >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalApiReturnsLabel">{{ `Número do contrato: ${descricao} - Evento: ${event} - Modelo: ${modelo}`}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">

                  <vue-good-table
                      :columns="columns_data"
                      :rows="detail"
                      :line-numbers="true"
                      :pagination-options="{
                                  enabled: true,
                                  mode: 'records',
                                  position: 'bottom',
                                  dropdownAllowAll: true,
                                  perPageDropdown: [10, 30, 50],
                                  nextLabel: 'Próximo',
                                  prevLabel: 'Anterior',
                                  rowsPerPageLabel: '',
                                  ofLabel: 'de',
                                  allLabel: 'Todos',
                                  pageLabel: '',
                                  perPageDropdownEnabled: true,
                                  compactMode: true,
                                }"
                      :search-options="{
                          enabled: true,
                          placeholder: 'Pesquisar',
                        }"
                      styleClass="vgt-table"></vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!--    modal cadastro de tipos   -->

      <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
    </div><!-- row -->
  </template>

  <script>
  import "vue-good-table/dist/vue-good-table.css";
  import {VueGoodTable} from "vue-good-table";
  import { SweetModal, SweetModalTab } from "sweet-modal-vue";
  import { ptBR } from "vuejs-datepicker/dist/locale";
  import Datepicker from "vuejs-datepicker";

  export default {
    data() {
      return {
        logs: [],
        atributos: {},
        dataInicial: "",
			  dataFinal: "",
			  periodoSelecionado: 'Últimos 30 dias',
			  calendarLanguage: ptBR,
        loading: false,
        columns_data: [
          {
            label: "Campo",
            field: "campo"
          },
          {
            label: "Valor Antigo",
            field: "antigo"
          },
          {
            label: "Valor Novo",
            field: "novo"
          }
        ],

        columns: [
            {
                label: "ID",
                field: "contrato_id"
            },
          {
            label: "Instituição",
            field: "user.banco.nome"
          },
          {
            label: "Usuário",
            field: "user.profile.name"
          },
          {
            label: "Número do Contrato",
            field: "num_contrato",
          },
          {
            label: "Evento",
            field: "event",
            formatFn: this.translateDescription,
          },
        {
            label: "Tipo de Operação",
            field: "tipo_operacao",
        },
          {
            label: "Data e Hora",
            field: "created_at",
            sortable: true,
            formatFn: this.formatDate,
          },
          {
            label: "Ação",
            field: "actions",
          }
        ],
        detail: [],
        descricao: "",
        event: "",
        modelo: "",
      }
    },
    mounted() {
      this.carregarLogs('sig');
    },
    methods: {

    carregarLogs(signal) {
			const dtInicial = this.dataInicial ? moment(this.dataInicial).format("YYYY-MM-DD") : "";
			const dtFinal = this.dataFinal ? moment(this.dataFinal).format("YYYY-MM-DD") : "";
			const urlApi = dtInicial === '' || dtFinal === '' ? '/portal/log/audit' : `/portal/log/audit/${dtInicial}/${dtFinal}`;

			// Atualiza a legenda antes de fazer a solicitação
			  this.atualizarLegenda();

			if ((signal == 'sig') || (dtInicial !== '' && dtFinal !== '')) {
				this.loading = true;
				axios.get(urlApi)
					.then((res) => {
						this.logs = res.data;
					})
					.catch((err) => {
						this.logs = [];
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
		atualizarLegenda() {
			if (this.dataInicial && this.dataFinal) {
			this.periodoSelecionado = `${moment(this.dataInicial).format("DD/MM/YYYY")} - ${moment(this.dataFinal).format("DD/MM/YYYY")}`;
			} else {
			this.periodoSelecionado = 'Últimos 30 dias';
			}
		},
		limparFiltros() {
			let _this = this;
			_this.dataInicial = "";
			_this.dataFinal = "";
			_this.periodoSelecionado = 'Últimos 30 dias';

			this.carregarLogs('sig')
		},

      showInfo(descricao, event, subjectType, atributos) {


      this.descricao = descricao;
      this.event = this.translateDescription(event);
      this.modelo = subjectType;

        let arr = [];

        if (atributos.attributes) {
          for (const key in atributos.attributes) {
            const novo = atributos?.attributes?.[key] || "";
            const antigo = atributos?.old?.[key] || "";

            arr.push({
              campo: key,
              antigo: antigo,
              novo: novo
            })
          }
        } else if (atributos.old) {
          for (const key in atributos.old) {

            const novo = atributos?.attributes?.[key] || "";
            const antigo = atributos?.old?.[key] || "";
            arr.push({
              campo: key,
              antigo: antigo,
              novo: novo
            })
          }
        }

        this.detail = arr;

        this.atributos = atributos;
        $("#modalDetailAudit").modal('show');
      },
      translateDescription: (value) => {
        if (value === 'created') return 'Registro criado';
        if (value === 'updated') return 'Registro atualizado';
        if (value === 'deleted') return 'Registro removido';
        return value;
      },
      formatDate: function (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
      }
    },
    components: {
      VueGoodTable,
      SweetModal,
      SweetModalTab,
      Datepicker,
    }
  }
  </script>
